import React from 'react';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import ImgFluid from './ImgFluid';

const TutoCard = ({ src, children, ...rest }) => (
  <Card elevation={3} {...rest}>
    <CardContent>
      {children}
    </CardContent>
    <CardMedia>
      <ImgFluid src={src} />
    </CardMedia>
  </Card>
);

export default TutoCard;
