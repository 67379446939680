import React from 'react';

import { Link } from 'gatsby';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import TutoCard from '../../components/TutoCard';

const commonStyle = {
  marginTop: '2em',
  marginBottom: '2em',
};

const NumerosDePhotos = () => (
  <Layout noheader>
    <SEO title="Marie & Benjamin - Trouver le numro d'une photo" />

    <Typography variant="h2" component="h1" gutterBottom>
      Touver le numéro d'une photo
    </Typography>

    <Button component={Link} to="marie-et-benjamin" variant="outlined" color="primary">
      <ArrowBackIcon /> Revenir à la liste des albums
    </Button>

    <TutoCard src="tuto-1.jpg" style={commonStyle}>
      <Typography>
        Lorsque vous parcourez les photographies d'un album, vous pouvez voir, en haut à droite,
        trois pictogrammes dont l'un représentant le <b>i</b> de <b>i</b>nformations :
      </Typography>
    </TutoCard>

    <TutoCard src="tuto-2.jpg" style={commonStyle}>
      <Typography>
        En cliquant sur ce dernier, un panneau d'information apparaît à droite de la photographie.
      </Typography>
    </TutoCard>

    <TutoCard src="tuto-3.jpg" style={commonStyle}>
      <Typography paragraph>
        Ce panneau d'information affiche notemment le nom, et donc le numéro de la photo
        actuellement affichée.<br />
        En général, il est sous la forme <tt>MB2019-XXXX.jpg</tt> où <tt>XXXX</tt> est un nombre
        entre 1 et 1500.<br />
      </Typography>
      <Typography>
        Il vous suffit alors de noter ce numéro. C'est celui-ci que vous devrez nous envoyez si
        vous souhaitez recevoir une version haute définition de celle-ci.
      </Typography>
    </TutoCard>

    <Button component={Link} to="marie-et-benjamin" variant="outlined" color="primary">
      <ArrowBackIcon /> Revenir à la liste des albums
    </Button>
  </Layout>
);

export default NumerosDePhotos;
